import * as React from "react";
import Image from "next/image";
import { Box, Typography, Grid, Button } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Link from "next/link";
import Layout from "../components/Layout/Layout";

import {
  EnglistCommunication,
  EnglistNoRoot,
  IeltsPreparation,
  ToeicPreparation,
} from "../constant/constant";
import CardBoxLists from "../components/CardBoxLists/CardBoxLists";
import FormHookTextField from "../components/FormHookTextField/FormHookTextField";
import { FormProvider, useForm } from "react-hook-form";
import { Banner, Customer, Post, Student, Teacher } from "../types/type";
import News from "../components/News/News";
import webApi from "./api/webApi";
import get from "lodash.get";
import fetcherApi from "./api/fetcher";

export async function getServerSideProps() {
  const [banners, teachers, students, posts] = await Promise.all([
    fetcherApi.fetcherBanners(),
    fetcherApi.fetcherTeachers(),
    fetcherApi.fetcherStudents(),
    fetcherApi.fetcherPosts(),
  ]);

  // Pass data to the page via props
  return { props: { banners, teachers, students, posts } };
}

interface HomeScreenProps {
  banners: Banner[];
  posts: Post[];
  teachers: Teacher[];
  students: Student[];
}

const HomeScreen = ({
  banners,
  teachers,
  students,
  posts,
}: HomeScreenProps) => {
  const methods = useForm<Customer>({
    mode: "onBlur",
    // resolver: yupResolver(schemaLogin),
  });

  return (
    <Layout banners={banners}>
      <Box maxWidth={1060} mx="auto" p={2} textAlign="center">
        <Typography
          variant="h2"
          fontSize={38}
          fontWeight={600}
          color="error"
          sx={{ pt: 5, mb: 2.5 }}
        >
          TỰ TIN TIẾNG ANH, SÁNH TẦM QUỐC TẾ
        </Typography>
        <Typography sx={{ mb: { xs: 4, sm: 2 } }}>
          Anh ngữ Uni English Center tự hào là một trong những đơn vị đào tạo có
          nhiều kinh nghiệm với đội ngũ giảng viên có chuyên môn cao, dày dặn
          kinh nghiệm trong lĩnh vực Tiếng Anh. Với phương châm Tâm - Tầm - Trí
          Tín trung tâm cam kết tận tâm đồng hành cùng phụ huynh, học sinh trên
          chặng đường xây dựng và phát triển cho từng học viên.
          <br />
          Chúng tôi vô cùng vinh dự đạt Top 10 Thương hiệu - Nhãn hiệu nổi tiếng
          đất Việt 2021. Đồng thời, đây cũng là thương hiệu đào tạo Tiếng Anh
          tiêu biểu tại Việt Nam trong khu vực ASEAN 2021.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Link href={EnglistNoRoot.path}>
              <Box position="relative" sx={{ cursor: "pointer" }}>
                <Image
                  src="/images/courses/course_1.jpg"
                  alt="img"
                  width={500}
                  height={500}
                ></Image>
                <Box
                  position="absolute"
                  color="white"
                  right={0}
                  bottom={-10}
                  sx={{
                    backgroundImage:
                      "linear-gradient(to bottom right, red, orange)",
                  }}
                >
                  <Typography variant="h5" px={2} py={1} fontWeight={600}>
                    Tiếng anh mất gốc
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link href={EnglistCommunication.path}>
              <Box position="relative" sx={{ cursor: "pointer" }}>
                <Image
                  src="/images/courses/course_2.jpg"
                  alt="img"
                  width={500}
                  height={500}
                ></Image>
                <Box
                  position="absolute"
                  color="white"
                  left={0}
                  bottom={-10}
                  sx={{
                    backgroundImage:
                      "linear-gradient(to bottom right, red, orange)",
                  }}
                >
                  <Typography variant="h5" py={1} px={2} fontWeight={600}>
                    Tiếng anh giao tiếp
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link href={ToeicPreparation.path}>
              <Box position="relative" sx={{ cursor: "pointer" }}>
                <Image
                  src="/images/courses/course_3.jpg"
                  alt="img"
                  width={500}
                  height={500}
                ></Image>
                <Box
                  position="absolute"
                  color="white"
                  right={0}
                  bottom={-10}
                  sx={{
                    backgroundImage:
                      "linear-gradient(to bottom right, red, orange)",
                  }}
                >
                  <Typography variant="h5" px={2} py={1} fontWeight={600}>
                    Luyện thi TOEIC
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link href={IeltsPreparation.path}>
              <Box position="relative" sx={{ cursor: "pointer" }}>
                <Image
                  src="/images/courses/course_4.jpg"
                  alt="img"
                  width={500}
                  height={500}
                ></Image>
                <Box
                  position="absolute"
                  color="white"
                  left={0}
                  bottom={-10}
                  sx={{
                    backgroundImage:
                      "linear-gradient(to bottom right, red, orange)",
                  }}
                >
                  <Typography variant="h5" py={1} px={2} fontWeight={600}>
                    Luyện thi IELTS
                  </Typography>
                </Box>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Box maxWidth={1060} mx="auto" textAlign="center" p={2}>
        <Typography
          variant="h1"
          fontSize={38}
          fontWeight={600}
          color="error"
          sx={{ pt: 5, mb: 2.5 }}
        >
          GIỚI THIỆU UNI ENGLISH CENTER
        </Typography>
        <Typography sx={{ mb: { xs: 4, sm: 2 } }}>
          Tại Uni, các giáo viên không chỉ có trình độ chuyên môn cao mà còn rất
          nhiệt tình, tâm huyết và yêu nghề
        </Typography>
        <Grid container maxWidth={1230} mx="auto">
          <Grid item xs={12} md={7} position="relative">
            <iframe
              width="100%"
              height="350"
              src="https://www.youtube.com/embed/FuPc4iPdI3U"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
              sx={{
                textAlign: { xs: "center", sm: "left" },
                pl: { sm: 4 },
                pb: { xs: 4 },
              }}
            >
              <Typography mt={2}>
                Mỗi giờ học đều sẽ được ghi dấu bởi tình yêu, và mong muốn
                truyền cảm hứng, niềm yêu thích học tiếng Anh dành cho học viên
                giúp các bạn đạt được những mục tiêu của mình trong công việc và
                cuộc sống. Hơn 2500 học viên đã đạt 7.0 - 8.5 IELTS chính là
                minh chứng rõ nét nhất về điều này.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box bgcolor="#f0f0f0">
        <CardBoxLists
          title="ĐỘI NGŨ GIẢNG VIÊN"
          content="Uni english center có đội ngũ giảng viên ưu tú hàng đầu"
          cardInfos={teachers}
          isBoxTeacher
        />
      </Box>

      <CardBoxLists
        title="GÓC VINH DANH HỌC VIÊN XUẤT SẮC"
        cardInfos={students}
      />

      {/* <Box bgcolor="#f0f0f0">
        <Box maxWidth={1060} mx="auto" p={2} textAlign="center">
          <Typography
            variant="h2"
            fontSize={38}
            fontWeight={600}
            color="error"
            sx={{ pt: 5, mb: 2.5 }}
          >
            NHỮNG CON SỐ ẤN TƯỢNG CỦA UNI ENGLISH CENTER
          </Typography>
          <Typography sx={{ mb: { xs: 4, sm: 2 } }}>
            Với 13 năm kinh nghiệm ...
          </Typography>
        </Box>
      </Box> */}
      <Box bgcolor="#f0f0f0">
        <News posts={posts} />
      </Box>

      <Box maxWidth={1060} mx="auto" p={2} textAlign="center">
        <Typography
          variant="h2"
          fontSize={38}
          fontWeight={600}
          color="error"
          sx={{ pt: 5, mb: 2.5 }}
        >
          ĐỐI TÁC KHÁCH HÀNG
        </Typography>
        <Grid container spacing={2} pb={3}>
          <Grid item xs={12} sm={4}>
            <Box
              py={3}
              boxShadow="0px 10px 40px 0px rgb(136 136 136 / 35%)"
              bgcolor="white"
            >
              <Image
                src="/images/logo-partners/logo-idp.png"
                alt="logo"
                width={210}
                height={100}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              py={3}
              boxShadow="0px 10px 40px 0px rgb(136 136 136 / 35%)"
              bgcolor="white"
            >
              <Image
                src="/images/logo-partners/logo-bc.jpeg"
                alt="logo"
                width={210}
                height={100}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              py={3}
              boxShadow="0px 10px 40px 0px rgb(136 136 136 / 35%)"
              bgcolor="white"
            >
              <Image
                src="/images/logo-partners/logo-elsa.png"
                alt="logo"
                width={210}
                height={100}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Box bgcolor="#f0f0f0">
        <Box maxWidth={1060} mx="auto" p={2} textAlign="center">
          <Grid container spacing={2} pb={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">Tư vấn miễn phí khóa học</Typography>
              <Typography variant="h4" mb={3}>
                tại Uni English Center
              </Typography>
              <Typography mb={3}>Học linh hoạt ở mọi lúc, mọi nơi</Typography>
              <Typography mb={3}>
                Có huấn luyện viên cá nhân hỗ trợ học tập
              </Typography>
              <Typography mb={3}>
                Các giáo viên bản ngữ chuyên nghiệp
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box bgcolor="#64CFE9" p={4} borderRadius="50px">
                <FormProvider {...methods}>
                  <FormHookTextField
                    label="Họ tên *"
                    name="name"
                    fullWidth
                    sx={{ mb: 2 }}
                    // errorMessage={errors.email?.message}
                    // onClick={() => setMessageError("")}
                  />
                  <FormHookTextField
                    label="Email *"
                    name="email"
                    fullWidth
                    sx={{ mb: 2 }}
                    // errorMessage={errors.email?.message}
                    // onClick={() => setMessageError("")}
                  />
                  <FormHookTextField
                    label="Số điện thoại *"
                    name="phone"
                    fullWidth
                    sx={{ mb: 2 }}
                    // errorMessage={errors.email?.message}
                    // onClick={() => setMessageError("")}
                  />
                  <FormHookTextField
                    label="Địa chỉ *"
                    name="address"
                    fullWidth
                    sx={{ mb: 2 }}
                    // errorMessage={errors.email?.message}
                    // onClick={() => setMessageError("")}
                  />
                  <Button variant="contained">Đăng ký ngay</Button>
                </FormProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box> */}
    </Layout>
  );
};

export default HomeScreen;
