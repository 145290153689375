import Image from "next/image";
import { Box, Typography } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { CardInfo } from "../../types/type";

interface CardBoxListsProps {
  title: string;
  content?: string;
  cardInfos: CardInfo[];
  isBoxTeacher?: boolean;
}

const CardBoxLists = ({
  title,
  content,
  cardInfos,
  isBoxTeacher,
}: CardBoxListsProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box maxWidth={1060} mx="auto" px={2} pt={2} textAlign="center">
      <Typography
        variant="h2"
        fontSize={38}
        fontWeight={600}
        color="error"
        sx={{ pt: { xs: 12.5, sm: 5 }, mb: { xs: 3.5, sm: 2.5 } }}
      >
        {title}
      </Typography>
      {content && (
        <Typography sx={{ mb: { xs: 4, sm: 2 } }}>{content}</Typography>
      )}
      <Swiper
        autoplay={{
          delay: isBoxTeacher ? 4000 : 3000,
        }}
        slidesPerView={matches ? 3 : 1}
        spaceBetween={6}
        slidesPerGroup={1}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        style={{ padding: "0 0 40px 0" }}
      >
        {cardInfos.map((cardInfo) => (
          <SwiperSlide
            key={cardInfo.id}
            style={{ height: "auto", padding: isBoxTeacher ? "130px 0 0" : 0 }}
          >
            <Box
              boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              bgcolor="white"
              textAlign="left"
              mx={1}
              mb={1}
              height="100%"
            >
              {isBoxTeacher ? (
                <>
                  <Box maxWidth={350}>
                    <Box pt={15} px={2} pb={2} position="relative">
                      <Box
                        width={250}
                        position="absolute"
                        sx={{ transform: "translate(-50%, -50%)" }}
                        top={0}
                        left="50%"
                      >
                        <Image
                          src={cardInfo.imageUrl}
                          alt="avatar"
                          width={250}
                          height={250}
                          objectFit="cover"
                          style={{
                            borderRadius: "50%",
                          }}
                        />
                      </Box>
                      <Typography
                        variant="h6"
                        px={2}
                        fontWeight={600}
                        textAlign="center"
                        color="error"
                      >
                        {cardInfo.name}
                      </Typography>
                      <Box mb={1}>
                        <b>Thành tích: </b>
                        <Typography display="inline">
                          {cardInfo.achievement}
                        </Typography>
                      </Box>
                      <Box mb={1}>
                        <b>
                          {cardInfo.graduate ? "Tốt nghiệp:" : "Phụ trách:"}{" "}
                        </b>
                        <Typography display="inline">
                          {cardInfo.graduate
                            ? cardInfo.graduate
                            : cardInfo?.inCharge}
                        </Typography>
                      </Box>
                      <Box mb={1}>
                        <b>Kinh nghiệm: </b>
                        <Typography display="inline">
                          {cardInfo.experience}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box position="relative" maxWidth={300} mx="auto">
                    <Image
                      src={cardInfo.imageUrl}
                      alt="avatar"
                      width={300}
                      height={300}
                      objectFit="cover"
                    />
                    <Box
                      position="absolute"
                      color="white"
                      left={0}
                      bottom={0}
                      sx={{
                        backgroundImage:
                          "linear-gradient(to bottom right, red, orange)",
                      }}
                    >
                      <Typography variant="h6" px={2} fontWeight={600}>
                        {cardInfo.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box p={2}>
                    <Box>
                      <b>Thành tích: </b>
                      <Typography color="error" display="inline">
                        {cardInfo.achievement}
                      </Typography>
                    </Box>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: cardInfo?.achievementDetail ?? "",
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default CardBoxLists;
