import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, Typography, Grid, Button } from "@mui/material";
import Image from "next/image";
import { Post } from "../../types/type";

interface NewsProps {
  posts: Post[];
}

const News = ({ posts }: NewsProps) => {
  return (
    <Box maxWidth={1060} mx="auto" p={2} textAlign="center">
      <Typography
        variant="h2"
        fontSize={38}
        fontWeight={600}
        color="error"
        sx={{ pt: 5, mb: 2.5 }}
      >
        TIN TỨC - SỰ KIỆN
      </Typography>
      <Grid container spacing={3} pb={3}>
        {posts.map((post) => (
          <Grid key={post.id} item xs={12} md={6} textAlign="left">
            <Box
              boxShadow={3}
              sx={{ cursor: "pointer" }}
              onClick={() => window.open(post.urlLink, "_blank")}
            >
              <Image
                width={500}
                height={380}
                src={post.imageUrl}
                alt="tin tuc"
                loading="lazy"
                objectFit="cover"
              />
              <Box p={2} bgcolor="white">
                <Typography
                  variant="h6"
                  mb={2}
                  fontWeight={600}
                  sx={{ color: "#003359" }}
                  dangerouslySetInnerHTML={{ __html: post.title }}
                />
                <Typography
                  dangerouslySetInnerHTML={{ __html: post.content }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    sx={{ fontSize: 14, ml: -1 }}
                    endIcon={<KeyboardDoubleArrowRightIcon sx={{ ml: -1 }} />}
                  >
                    Đọc tiếp
                  </Button>
                  <Typography variant="subtitle2">
                    {post.publishDate}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default News;
